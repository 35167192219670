import * as React from "react";
import { Form, Formik } from "formik";
import FormikTextInput from "@components/FormikTextInput";
import { toastError, toastMessage } from "@utils/functions";
import { User } from "@utils/types";
import { useNavigate } from "react-router-dom";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
interface NewSellerFormValues {
  name: string;
  email: string;
  phone: string;
  password: string;
  rePassword: string;
}

const AddSellersPage: React.FC = () => {
  const formInitialValues: NewSellerFormValues = React.useMemo(() => {
    const initialValues = {
      name: "",
      email: "",
      phone: "",
      password: "",
      rePassword: "",
    };
    return initialValues;
  }, []);

  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const companyData = useSelector((state: RootState) => state.company.data);

  const onSellerSave = async (seller: User) => {
    try {
      toastMessage("Vendedor adicionado", "success");
      navigate("/vendedores");
    } catch (error) {
      toastError(error);
    }
  };
  const handleSubmit = async (values: NewSellerFormValues) => {
    try {
      if (values.password !== values.rePassword) {
        throw Error("As senhas não conferem");
      }
      const { data } = await Api.post(`/company/${companyData.id}/add_seller`, values);
      onSellerSave(data);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <div className="row m-0 py-2" style={{ backgroundColor: "white" }}>
      <div className="col-12 col-lg-6">
        <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form className="row">
              <div className="col-12">
                <FormikTextInput name="name" mask="" label="Nome do vendedor" required />
              </div>
              <div className="col-12 mt-3">
                <FormikTextInput name="email" mask="" label="Email do vendedor" required />
              </div>
              <div className="col-12 mt-3">
                <FormikTextInput
                  name="phone"
                  mask="(99)99999-9999"
                  label="Telefone"
                  placeholder="(__) _____-____"
                  required
                />
              </div>
              <div className="mt-3 d-flex flex-row align-content-center">
                <FormikTextInput
                  name="password"
                  type={showPassword ? "text" : "password"}
                  mask=""
                  label="Senha do vendedor"
                  required
                />
                <span
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              <div className="col-12 mt-3">
                <FormikTextInput
                  name="rePassword"
                  type={showPassword ? "text" : "password"}
                  mask=""
                  label="Confirme a senha"
                  required
                />
              </div>
              {values.password !== values.rePassword && values.rePassword !== "" && (
                <span className="text-danger">As senhas não conferem</span>
              )}
              <div className="col-12 border-top mt-3 pt-3">
                <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Enviando" : "Enviar"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddSellersPage;
