import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ContentContainer from "@components/ContentContainer";
import PrivateRoute from "@components/PrivateRoute";
import TopBar from "@components/TopBar";
import DashboardScreen from "@pages/Dashboard";
import ConnectionErrorPage from "@pages/Errors/ConnectionError";
import LoginScreen from "@pages/Login";
import LogoutScreen from "@pages/Logout";
import OrdersPage from "@pages/Orders";
import AddProductPage from "@pages/Products/Add";
import AddSizePage from "@pages/Sizes/Add";
import ListSizesPage from "@pages/Sizes/List";
import { useAppDispatch } from "@services/Redux";
import { fetchCompany } from "@services/Redux/company";

import AddColorPage from "@pages/Colors/Add";
import EditColorPage from "@pages/Colors/Edit";
import ListColorsPage from "@pages/Colors/List";
import OrderDetailsPage from "@pages/OrderDetails";
import OrderPaymentConfirmationScreen from "@pages/OrderPaymentConfirmation";
import OrderPaymentsPage from "@pages/OrderPayments";
import EditProductPage from "@pages/Products/Edit";
import ListProductsPage from "@pages/Products/List";
import SettingsPage from "@pages/Settings";
import EditSizePage from "@pages/Sizes/Edit";

import AdminDashboard from "@pages/AdminDashboard";
import AdminCompaniesList from "@pages/AdminDashboard/Companies/Index";
import AddCouponPage from "@pages/Coupons/pages/Add";
import ListCouponsPage from "@pages/Coupons/pages/Index";
import CustomersPage from "@pages/Customers";
import StockListPage from "@pages/StockList";

// Importing styles
import SingleCustomerPage from "@pages/Customers/SingleCustomer";
import { ReportsPage } from "@pages/Reports";
import AddSellersPage from "@pages/Sellers/Add";
import EditSellersPage from "@pages/Sellers/Edit";
import ListSellersPage from "@pages/Sellers/List";
import OrderForm from "@pages/Settings/OrderForm";
import StockSettings from "@pages/Settings/Stock";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.min.css";
import { TooltipProvider } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./styles/global.scss";
import env from "@utils/env";
import ForgotPasswordScreen from "@pages/ForgotPassword";
import ResetPasswordScreen from "@pages/ResetPassword";
import CompanyDetailsPage from "@pages/AdminDashboard/CompanyDetails";
import EditCompanyPage from "@pages/AdminDashboard/EditCompany";
import { IntegrationsPage } from "@pages/Integrations";
import { BlingAuthRedirect } from "@pages/Integrations/bling/auth-redirect";
import { BlingProductsPage } from "@pages/Integrations/bling/products.page";

const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const company_code = localStorage.getItem("company_code");
    if (company_code) dispatch(fetchCompany(company_code ?? ""));
    if (env.REACT_APP_ICON_URL) {
      const link = document.getElementById("favicon") as any;
      link.href = env.REACT_APP_ICON_URL;
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <TopBar />
      <ContentContainer>
        <Routes>
          <Route path="/" element={<Navigate to="/pedidos" />} />
          <Route path="/admin_dashboard" element={<AdminDashboard />} />
          <Route path="/marcas" element={<AdminCompaniesList />} />
          <Route path="/marcas/:company_id/detalhes" element={<CompanyDetailsPage />} />
          <Route path="/marcas/:company_code/editar" element={<EditCompanyPage />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/pedidos" element={<OrdersPage />} />
          <Route path="/pedidos/:order_code/pagamentos" element={<OrderPaymentsPage />} />
          <Route path="/pedidos/:order_code/pagamentos/confirmar" element={<OrderPaymentConfirmationScreen />} />
          <Route path="/produtos" element={<ListProductsPage />} />
          <Route path="/produtos/cadastrar" element={<AddProductPage />} />
          <Route path="/clientes" element={<CustomersPage />} />
          <Route path="/clientes/:id" element={<SingleCustomerPage />} />
          <Route path="/estoque" element={<StockListPage />} />
          <Route path="/produtos/:product_id/editar" element={<EditProductPage />} />
          <Route path="/configuracoes" element={<SettingsPage />} />
          <Route path="/configuracoes/gerais" element={<SettingsPage />} />
          <Route path="/configuracoes/gerenciar-estoque" element={<StockSettings />} />
          <Route path="/configuracoes/formulario_pedido" element={<OrderForm />} />
          <Route path="/configuracoes/tamanhos" element={<ListSizesPage />} />
          <Route path="/configuracoes/tamanhos/cadastrar" element={<AddSizePage />} />
          <Route path="/configuracoes/tamanhos/:size_id/editar" element={<EditSizePage />} />
          <Route path="/configuracoes/cores" element={<ListColorsPage />} />
          <Route path="/configuracoes/cores/cadastrar" element={<AddColorPage />} />
          <Route path="/configuracoes/cores/:color_id/editar" element={<EditColorPage />} />
          <Route path="/configuracoes/integracoes" element={<IntegrationsPage />} />
          <Route path="/configuracoes/integracoes/bling/produtos" element={<BlingProductsPage />} />
          <Route path="/cupons" element={<ListCouponsPage />} />
          <Route path="/cupons/cadastrar" element={<AddCouponPage />} />
          <Route path="/vendedores" element={<ListSellersPage />} />
          <Route path="/vendedores/cadastrar" element={<AddSellersPage />} />
          <Route path="/vendedores/:seller_id/editar" element={<EditSellersPage />} />
          <Route path="/relatorios" element={<ReportsPage />} />
        </Routes>
      </ContentContainer>
    </>
  );
};

const App: React.FC = () => {
  return (
    <>
      <TooltipProvider>
        <Routes>
          {/* Public screens */}
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/logout" element={<LogoutScreen />} />
          <Route path="/erro" element={<ConnectionErrorPage />} />
          <Route path="/pedido/:order_code" element={<OrderDetailsPage />} />
          <Route path="/esqueci_minha_senha" element={<ForgotPasswordScreen />} />
          <Route path="/redefinir_senha" element={<ResetPasswordScreen />} />
          <Route path="/configuracoes/integracoes/bling/redirect" element={<BlingAuthRedirect />} />
          {/* Home screen with all authenticated app routes */}
          <Route path="/*" element={<PrivateRoute component={AppRoutes} />} />
        </Routes>
        <ToastContainer />
      </TooltipProvider>
    </>
  );
};

export default App;
