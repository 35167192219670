import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { OrderStatus, CustomerWithLastOrder } from "@utils/types";
import moment from "@utils/moment";
import store from ".";

interface CustomersFilters {
  status: OrderStatus;
  startDate: string;
  endDate: string;
  page: number;
  perPage: number;
  dateRangeSelectStep: number;
  sort: "ASC" | "DESC";
  searchTerm: string;
}

const customers = createSlice({
  name: "customers",
  initialState: {
    loading: true,
    data: [] as CustomerWithLastOrder[],
    count: 0,
    filters: {
      status: OrderStatus.PAID,
      startDate: moment().subtract(1, "month").format(),
      endDate: moment().format(),
      sort: "DESC",
      dateRangeSelectStep: 0,
      page: 1,
      perPage: 10,
      searchTerm: "",
    } as CustomersFilters,
  },
  reducers: {
    setCustomersFilters(state, action: PayloadAction<CustomersFilters>) {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.data = action.payload.customers;
      state.count = action.payload.count;
      state.loading = false;
    });
    builder.addCase(fetchCustomers.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default customers.reducer;

export const { setCustomersFilters } = customers.actions;

export const fetchCustomers = createAsyncThunk("customers", async (typePrefix, { rejectWithValue }) => {
  try {
    const { company, customers } = store.getState();
    const { page, perPage, startDate, endDate, status, sort, searchTerm } = customers.filters;
    const url = `/company/customers`;
    const query = `?company_code=${company.data
      ?.code}&search=${searchTerm}&status=${status}&sort=${sort}&page=${page}&perPage=${perPage}&startDate=${encodeURIComponent(
      moment(startDate).format("DD/MM/YYYY"),
    )}&endDate=${encodeURIComponent(moment(endDate).format("DD/MM/YYYY"))}`;
    const { data } = await Api.get(url + query);
    return data;
  } catch (error) {
    toastError(error);
    throw rejectWithValue(error);
  }
});
