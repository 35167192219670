import InfoCard from "@components/InfoCard";
import Select from "@components/Select";
import Api from "@services/Api";
import { toastError } from "@utils/functions";
import moment from "@utils/moment";
import { Money } from "@utils/money";
import { Company, SelectOption } from "@utils/types";
import * as React from "react";
import { BsClipboardData } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const intervalOptions = [
  { value: moment().subtract(30, "days"), label: "Últimos 30 dias" },
  { value: moment().subtract(60, "days"), label: "Últimos 60 dias" },
  { value: moment().subtract(6, "months"), label: "Últimos 6 meses" },
  { value: moment().startOf("year"), label: "Este ano ano" },
] as SelectOption[];

interface CompanyWithMetrics extends Partial<Company> {
  paid_sales_count: number;
  paid_sales_value: number;
  paid_sales_series: { date: string; count: number; value: number }[];
}

const AdminDashboard: React.FC = () => {
  const [startDate, setStartDate] = React.useState(intervalOptions[0].value);
  const [companiesMetrics, setCompaniesMetrics] = React.useState<CompanyWithMetrics[]>([]);
  const [loadingMetrics, setLoadingMetrics] = React.useState(true);

  const amountSeries = companiesMetrics
    .filter((m) => m.paid_sales_count > 0)
    .map((m) => ({
      name: m.name,
      data: m.paid_sales_series.map((s) => ({
        category: s.date,
        value: s.value,
      })),
    }));

  const countSeries = companiesMetrics
    .filter((m) => m.paid_sales_count > 0)
    .map((m) => ({
      name: m.name,
      data: m.paid_sales_series.map((s) => ({
        category: s.date,
        value: s.count,
      })),
    }));

  const fetchMetrics = async () => {
    try {
      const { data } = await Api.get(
        `/metrics/companies_sales?startDate=${encodeURIComponent(
          startDate.format("DD/MM/YYYY"),
        )}&endDate=${encodeURIComponent(moment().format("DD/MM/YYYY"))}`,
      );
      setCompaniesMetrics(data.result);
      setLoadingMetrics(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fetchMetrics();
    // eslint-disable-next-line
  }, [startDate]);

  return (
    <div className={"row m-0"}>
      <div className={"col-6 col-md-3 p-2"}>
        <Select
          options={intervalOptions}
          defaultValue={intervalOptions[0]}
          onChange={(value) => {
            const option = value as SelectOption;
            setStartDate(intervalOptions.find((d) => d.value === option.value)?.value ?? intervalOptions[0].value);
          }}
        />
      </div>
      <div className="w-100 d-none d-lg-block" />
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <InfoCard
          title={`Pedidos pagos`}
          description={companiesMetrics.reduce((total, company) => total + company.paid_sales_count, 0)}
          color="#2546BB"
          icon={<BsClipboardData />}
          loading={loadingMetrics}
        />
      </div>
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <InfoCard
          title={`Total em pedidos pagos`}
          description={Money(
            companiesMetrics.reduce((total, company) => Money(total).add(company.paid_sales_value).value, 0),
          ).format()}
          color="#64de57"
          icon={<MdAttachMoney />}
          loading={loadingMetrics}
        />
      </div>
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2 "}>
        <div className={"bg-white p-2 rounded-3"}>
          <h5>Quantidade de vendas</h5>
          <ResponsiveContainer width={"100%"} height={400} className={"bg-white"}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="category"
                type="category"
                tickFormatter={(tick) => moment(tick, "DD/MM/YYYY").format("DD/MM")}
                allowDuplicatedCategory={false}
              />
              <YAxis dataKey="value" />
              <Tooltip formatter={(value: any) => Money(value).format()} />
              <Legend />
              {amountSeries.map((s) => (
                <Line type="monotone" dataKey="value" data={s.data} name={s.name} key={s.name} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <div className={"bg-white p-2 rounded-3"}>
          <h5>Valor em vendas</h5>
          <ResponsiveContainer width={"100%"} height={400}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="category"
                type="category"
                tickFormatter={(tick) => moment(tick, "DD/MM/YYYY").format("DD/MM")}
                allowDuplicatedCategory={false}
              />
              <YAxis dataKey="value" />
              <Tooltip />
              <Legend />
              {countSeries.map((s) => (
                <Line type="monotone" dataKey="value" data={s.data} name={s.name} key={s.name} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
