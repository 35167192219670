import * as React from "react";
import InfoCard from "@components/InfoCard";
import Select from "@components/Select";
import { OrderStatus, SelectOption } from "@utils/types";
import { BsClipboardData } from "react-icons/bs";
import { getStatusColor } from "@utils/functions";
import { Money } from "@utils/money";
import moment from "@utils/moment";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useMetrics } from "./hooks/useMetrics";

const intervalOptions = [
  { value: moment().subtract(30, "days"), label: "Últimos 30 dias" },
  { value: moment().subtract(60, "days"), label: "Últimos 60 dias" },
  { value: moment().subtract(6, "months"), label: "Últimos 6 meses" },
  { value: moment().subtract(1, "year"), label: "Último ano" },
] as SelectOption[];

const Dashboard: React.FC = () => {
  const [startDate, setStartDate] = React.useState(intervalOptions[0].value);
  const { fetchMetrics, ordersCount, loadingMetrics } = useMetrics({ startDate });

  React.useEffect(() => {
    fetchMetrics();
    // eslint-disable-next-line
  }, [startDate]);

  return (
    <div>
      <div className="row">
        <div className={"col-md-3"}>
          <Select
            options={intervalOptions}
            defaultValue={intervalOptions[0]}
            onChange={(value) => {
              const option = value as SelectOption;
              setStartDate(intervalOptions.find((d) => d.value === option.value)?.value ?? intervalOptions[0].value);
            }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className={"col-12 mb-3 col-md-4"}>
          <InfoCard
            title={`Pedidos aguardando confirmação`}
            description={ordersCount.waiting_seller_confirmation.count}
            color={getStatusColor(OrderStatus.WAITING_SELLER_CONFIRMATION)}
            subDescription={Money(ordersCount.waiting_seller_confirmation.total_value).format()}
            icon={<AiOutlineExclamationCircle />}
            loading={loadingMetrics}
          />
        </div>
        <div className={"col-12 mb-3 col-md-4"}>
          <InfoCard
            title={`Pedidos aguardando pagamento`}
            description={ordersCount.ready_to_pay.count}
            subDescription={Money(ordersCount.ready_to_pay.total_value).format()}
            color={getStatusColor(OrderStatus.READY_TO_PAY)}
            icon={<AiOutlineExclamationCircle />}
            loading={loadingMetrics}
          />
        </div>
        <div className={"col-12 mb-3 col-md-4"}>
          <InfoCard
            title={`Pedidos pagos`}
            description={ordersCount.paid.count}
            color={getStatusColor(OrderStatus.PAID)}
            subDescription={Money(ordersCount.paid.total_value).format()}
            icon={<BsClipboardData />}
            loading={loadingMetrics}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
