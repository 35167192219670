import FullScreenLoading from "@components/FullScreenLoading";
import Api from "@services/Api";
import { RootState, useAppDispatch } from "@services/Redux";
import {
  resetFormData,
  setLoadingEditProductFormData,
  setPriceData,
  setProduct,
  setStockEnabled,
} from "@services/Redux/editProductFormData";
import { toastError } from "@utils/functions";
import { Product, ProductVariation, UserRole } from "@utils/types";
import * as React from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ProductImagesForm from "./ImagesForm";
import EditProductForm from "./InformationForm";
import StockForm from "./StockForm";
import VariationPriceForm from "./VariationPriceForm";

const EditProductPage: React.FC = () => {
  const { product_id } = useParams();
  const { product, loading, loadingPriceData } = useSelector((state: RootState) => state.editProductFormData);
  const userRole = localStorage.getItem("user_role");
  const dispatch = useAppDispatch();
  const refSubmitButton = React.useRef<HTMLButtonElement>(null);
  const fetchProductData = async () => {
    try {
      const { data: productData } = await Api.get(`/product?product_id=${product_id}`);
      const { data: variationsData } = await Api.get(`/product/${product_id}/variation`);
      const fetchedProduct = productData.products[0] as Product;
      dispatch(setProduct({ ...fetchedProduct, variations: variationsData.variations }));
      dispatch(setLoadingEditProductFormData(false));
      dispatch(setStockEnabled(fetchedProduct.stock_enabled));
      dispatch(
        setPriceData(
          variationsData.variations.map((v: ProductVariation) => ({
            color_id: v.color.id,
            size_id: v.size.id,
            price: v.price,
          }))
        )
      );
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fetchProductData();
    return () => {
      dispatch(resetFormData());
    };
    // eslint-disable-next-line
  }, []);

  if (loading || !product) return <FullScreenLoading />;
  /*
   */
  return (
    <div className="col rounded-3 py bg-white">
      <div className={"row m-0 pt-2 rounded-3"} style={{ backgroundColor: "white" }}>
        <div className={"d-lg-none"}>
          <ProductImagesForm />
        </div>
        <div className={"col-12 order-1 order-lg-1 col-lg-6"}>
          <EditProductForm refSubmitButton={refSubmitButton} />
        </div>
        <div className="col-12 order-2 mt-lg-0 mt-3 order-lg-2 col-lg-6 mb-3 mb-lg-0">
          <div className="d-none d-lg-block">
            <ProductImagesForm />
          </div>
          {loadingPriceData ? null : <VariationPriceForm />}
          <div className={"mt-3 border-top pt-3"}>{userRole === UserRole.COMPANY_ADMIN && <StockForm />}</div>
        </div>
      </div>
      <div className={"col-12 p-3"}>
        <button
          onClick={() => {
            if (refSubmitButton.current) refSubmitButton.current.click();
            ReactGA.event({
              action: "product_edit",
              category: "Product",
              label: "Finalizou edição de produto",
            });
          }}
          className={"btn btn-primary"}
          type="submit"
        >
          Enviar
        </button>
      </div>
    </div>
  );
};

export default EditProductPage;
