export interface User {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  phone: string;
  company: Company;
  orders: Order[];
  created_at: Date;
  updated_at: Date;
  is_active: boolean;
}

export enum CnpjDiscountType {
  PERCENTAGE = "percentage",
  FIXED = "fixed",
}

export enum OrderStatus {
  CREATED = "created",
  WAITING_SELLER_CONFIRMATION = "waiting_seller_confirmation",
  READY_TO_PAY = "ready_to_pay",
  WAITING_PAYMENT_CONFIRMATION = "waiting_payment_confirmation",
  IN_ANALYSIS = "in_analysis",
  PAID = "paid",
  CANCELLED = "cancelled",
}

export enum OrderCustomerDocumentType {
  CPF = "cpf",
  CNPJ = "cnpj",
}

export enum UserRole {
  ADMIN = "admin",
  COMPANY_ADMIN = "company_admin",
  COMPANY_SELLER = "company_seller",
  CLIENT = "client",
}

export enum PaymentMethod {
  CREDIT_CARD = "credit_card",
  PIX = "pix",
  BOLETO = "boleto",
  MONEY = "money",
  BANK_TRANSFER = "bank_transfer",
}

export enum PaymentStatus {
  CREATED = "created",
  PAID = "paid",
  CANCELED = "canceled",
  ANALYSIS = "analysis",
  REJECTED = "rejected",
  EXPIRED = "expired",
}

export enum CreditCardBrands {
  VISA = "visa",
  MASTERCARD = "mastercard",
  DINERS = "diners",
  ELO = "elo",
  HIPER_ITAU = "hiper_itau",
  HIPERCARD = "hipercard",
  AMEX = "amex",
  AMERICAN_EXPRESS = "american_express",
  DISCOVER = "discover",
  AURA = "aura",
  JCB = "jcb",
}

export enum PaymentGateways {
  EMEPAG = "emepag",
  WIRECARD = "wirecard",
  YAPAY = "yapay",
  MANUAL = "manual",
}

interface DateProperties {
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
}

export interface Company {
  id: number;
  name: string;
  code: string;
  bio?: string;
  logo_image?: Image;
  cover_image?: Image;
  banners: Image[];
  default_seller?: User;
  order_automatic_flow?: boolean;
  requires_birthdate?: boolean;
  requires_customer_address?: boolean;
  requires_customer_phone_on_register?: boolean;
  max_installments_count?: number;
  is_shipment_enabled?: boolean;
  is_product_observations_enabled?: boolean;
  cash_payment_discount_percentage?: number;
  cnpj_discount_type?: CnpjDiscountType;
  cnpj_discount_percentage?: number;
  cnpj_discount_value?: number;
  minimun_order_value?: number;
  minimun_pieces_number?: number;
  require_login_to_buy?: boolean;
  emepag_api_token?: string;
  emepag_v2_api_token?: string;
  is_payment_enabled?: boolean;
  is_maintenance_mode_enabled: boolean;
  maintenance_mode_message?: string;
  custom_domain?: string;
  requires_email: boolean;
  requires_document: boolean;
  custom_domain_dns_target?: string;
  whatsapp_button_url?: string;
  is_whatsapp_button_enabled?: boolean;
  order_status_to_settle_stock: OrderStatus;
  created_at: Date;
  phone: string;
  is_excursion_info_required?: boolean;
  is_pickup_enabled?: boolean;
  show_stock_to_customer?: boolean;
  gtm_container_id?: string;
  bling_integration?: Integration;
}

export interface Integration {
  is_authorized: boolean;
}

export interface Category {
  id: number;
  name: string;
  children: Category[];
  parent?: Category;
}

export interface Product extends DateProperties {
  id: number;
  name: string;
  ref?: string;
  description: string;
  on_sale: boolean;
  price: number;
  categories: Category[];
  images: Image[];
  is_active: boolean;
  variations: ProductVariation[];
  stock_enabled: boolean;
}

export interface Color {
  id: number;
  name: string;
  image?: Image;
}

export interface Size {
  id: number;
  name: string;
}

export interface ProductVariation {
  id: number;
  sku?: string;
  price: number;
  color: Color;
  size: Size;
  stock: number;
  size_priority?: number;
  color_priority?: number;
}

export interface Order extends DateProperties {
  id: number;
  code: string;
  company_id: number;
  status: OrderStatus;
  value: number;
  customer_name?: string;
  customer_phone?: string;
  finished_at?: Date;
  confirmed_at?: Date;
  cancelled_at?: Date;
  last_payment_attempt?: Date;
  entered_in_analysis_at?: Date;
  paid_at?: Date;
  created_at: Date;
  items: OrderItem[];
  payments?: OrderPayment[];
  shipment_value?: number;
  emepag_order_code?: string;
  emepag_v2_order_code?: string;
  observations?: string;
  customer_document_type: OrderCustomerDocumentType;
  customer_document: string;
  customer_birthdate?: string;
  discounts_value?: number;
  shipment?: OrderShipment;
  coupon?: Coupon;
  seller?: User;
}

export interface OrderItem extends DateProperties {
  quantity: number;
  product: Product;
  variation: ProductVariation;
  price: number;
  size: string;
  color: string;
  observations?: string;
}

export interface Image {
  id: number;
  base_url: string;
  key: string;
  deleted_at?: string;
}

export interface VariationsValuesPayload {
  variation: ProductVariation;
  quantity: number;
}

/* OrderProduct is different of OrderItem because it groups OrderItems's products */
export type OrderProduct = Product & {
  items: OrderItem[];
};

export interface SelectOption<T = any> {
  value: T;
  label: string;
}

export interface BackofficeMetrics {
  ordersCount: number;
  ordersValue: number;
}

export interface OrderPayment {
  id: number;
  status: PaymentStatus;
  gateway?: PaymentGateways;
  gateway_order_id?: string;
  payment_method?: PaymentMethod;
  credit_card_brand?: CreditCardBrands;
  installments_count?: number;
  manually_set_paid: boolean;
  paid_at?: Date;
  expires_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export enum ShipmentStatus {
  CREATED = "created",
  SENT = "sent",
  DELIVERED = "delivered",
  CANCELLED = "cancelled",
}

export enum ShippingMethod {
  EXCURSION = "excursion",
  CORREIOS = "correios",
  SHIPPING_COMPANY = "shipping_company",
  CUSTOM = "custom",
  PICKUP = "pickup",
}

export interface OrderShipment extends DateProperties {
  id: number;
  status: ShipmentStatus;
  shipping_method: ShippingMethod;
  tracking_code?: string;
  excursion_name?: string;
  excursion_phone?: string;
  excursion_address?: string;
  excursion_address_reference?: string;
  observations?: string;
  zip_code?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
}

export interface CustomerWithLastOrder {
  id: number;
  name: string;
  email: string;
  phone: string;
  last_order: Order;
}

export interface CouponConditions {
  min_order_value?: number;
  min_items_count?: number;
  payment_method?: string;
  shipping_method?: string;
  region_city?: string;
  region_state?: string;
  per_user_limit?: number;
  expires_at?: string;
  first_order_only?: boolean;
  usage_limit?: number;
  usage_per_user?: number;
}

export interface Coupon {
  id: number;
  code: string;
  conditions: CouponConditions;
  description?: string;
  discount_percentage?: number;
  discount_fixed_value?: number;
  usage_count: number;
  active: boolean;
  max_discount_value: number;
}

export interface User {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  phone: string;
  company: Company;
  orders: Order[];
  created_at: Date;
  updated_at: Date;
  is_active: boolean;
}

export type OrderByOptions =
  | "created_at"
  | "updated_at"
  | "finished_at"
  | "cancelled_at"
  | "confirmed_at"
  | "last_payment_attempt"
  | "entered_in_analysis_at"
  | "paid_at";

export type FilterActiveProductsOptions = true | false;
