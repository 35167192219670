import * as React from "react";
import Styled from "./styled";
import ReactLoading from "react-loading";

interface InforCardProps {
  title: string;
  description: string | number;
  subDescription?: string;
  icon: React.ReactElement;
  color: React.CSSProperties["color"];
  loading?: boolean;
}

const InfoCard: React.FC<InforCardProps> = ({ title, description, color, icon, loading, subDescription }) => {
  return (
    <Styled.RootContainer color={color}>
      <Styled.IconContainer color={color}>{icon}</Styled.IconContainer>
      <div className="w-100">
        <div>
          <span>{title}</span>
        </div>
        <div>
          {loading ? (
            <ReactLoading type="bubbles" color="black" width={40} height={40} />
          ) : (
            <div className="d-flex flex-row align-items-center  justify-content-between justify-content-md-start">
              <Styled.CardInfoText>{description}</Styled.CardInfoText>
              {subDescription && <Styled.CardSubDescriptionText>{subDescription}</Styled.CardSubDescriptionText>}
            </div>
          )}
        </div>
      </div>
    </Styled.RootContainer>
  );
};

export default InfoCard;
