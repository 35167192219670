import FormikTextInput from "@components/FormikTextInput";
import FullScreenLoading from "@components/FullScreenLoading";
import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import Select from "@components/Select";
import SortableSelect from "@components/SortableSelect";
import { TooltipTexts } from "@pages/Products/tooltip-texts";
import Api from "@services/Api";
import { RootState, useAppDispatch } from "@services/Redux";
import {
  resetFormData,
  setLoadingPriceData,
  setPriceData,
  setVariationsData,
} from "@services/Redux/editProductFormData";
import { arrayMove, toastError, toastMessage, uniqueBy } from "@utils/functions";
import { Category, Color, SelectOption, Size, UserRole } from "@utils/types";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

interface NewProductFormValues {
  name: string;
  description?: string;
  price: number;
  ref: string;
}
interface EditProductFormProps {
  refSubmitButton?: React.RefObject<HTMLButtonElement>;
}

const EditProductForm: React.FC<EditProductFormProps> = (props) => {
  const userRole = localStorage.getItem("user_role");
  const { product, loading, stockData, priceData, stockEnabled, variationsData } = useSelector((state: RootState) => state.editProductFormData);
  const companyData = useSelector((state: RootState) => state.company.data);
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [sizes, setSizes] = React.useState<Size[]>([]);
  const [colors, setColors] = React.useState<Color[]>([]);
  const [loadingData, setLoadingData] = React.useState(true);
  const [selectedCategories, setSelectedCategories] = React.useState<SelectOption[]>([]);
  const [selectedColors, setSelectedColors] = React.useState<SelectOption[]>([]);
  const [selectedSizes, setSelectedSizes] = React.useState<SelectOption[]>([]);
  const descriptionCharsLimit = 180;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /* Logic functions */
  const fetchData = async () => {
    try {
      const { data: categoriesData } = await Api.get("/category");
      const { data: sizesData } = await Api.get(`/company/${companyData.id}/size`);
      const { data: colorsData } = await Api.get(`/company/${companyData.id}/color`);
      setCategories(categoriesData.categories);
      setSizes(sizesData.sizes);
      setColors(colorsData.colors);
      setLoadingData(false);
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmit = async (values: NewProductFormValues, actions: FormikHelpers<NewProductFormValues>) => {
    try {
      actions.setSubmitting(true);
      const variations: {
        color_id: number;
        size_id: number;
        price: number;
        stock: number;
        color_priority: number;
        size_priority: number;
      }[] = [];
      selectedColors.forEach((color, colorIndex) => {
        selectedSizes.forEach((size, sizeIndex) => {
          variations.push({
            color_id: color.value,
            size_id: size.value,
            price: values.price,
            stock: userRole === UserRole.COMPANY_ADMIN ? stockData.find((stock) => stock.color_id === +color.value && stock.size_id === +size.value)?.quantity ?? 0 : 0,
            color_priority: colorIndex,
            size_priority: sizeIndex,
          });
        });
      });
      const variationsToDelete = product?.variations.filter((v) => !variations.some((v2) => v.color.id === v2.color_id && v.size.id === v2.size_id)) ?? [];
      const variationsToAdd = variations.filter((v) => !product?.variations.some((v2) => v.color_id === v2.color.id && v.size_id === v2.size.id));
      await Api.patch(`/product/${product?.id}`, {
        ...values,
        stock_enabled: userRole === UserRole.COMPANY_ADMIN ? stockEnabled : undefined,
        categories: selectedCategories.map((c) => c.value),
      });

      if (variationsToDelete.length > 0) {
        await Api.delete(`/product/${product?.id}/variation/batch`, {
          data: { variations: variationsToDelete.map((v) => v.id) },
        });
      }
      if (variationsToAdd.length > 0) {
        await Api.post(`/product/${product?.id}/variation/batch`, { variations: variationsToAdd });
      }

      for await (const variation of product.variations) {
        const newPrice = priceData.find((price) => price.color_id === variation.color.id && price.size_id === variation.size.id)?.price;
        const newStock =
          userRole === UserRole.COMPANY_ADMIN
            ? stockData.find((stock) => stock.color_id === variation.color.id && stock.size_id === variation.size.id)?.quantity
            : undefined;
        const newVariation = variations.find((v) => v.size_id === variation.size.id && v.color_id === variation.color.id);

        if (
          (variation.stock !== newStock ||
            variation.price !== newPrice ||
            newVariation?.size_priority !== variation.size_priority ||
            newVariation?.color_priority !== variation.color_priority) &&
          !variationsToDelete.find((v) => v.id === variation.id)
        ) {
          let data = {} as Partial<{ price: number; stock: number; color_priority: number; size_priority: number }>;
          if (variation.stock !== newStock) data.stock = newStock;
          if (variation.price !== newPrice) data.price = newPrice;
          if (newVariation?.size_priority !== variation.size_priority) data.size_priority = newVariation?.size_priority;
          if (newVariation?.color_priority !== variation.color_priority)
            data.color_priority = newVariation?.color_priority;
          await Api.patch(`/product/${product?.id}/variation/${variation.id}`, data);
        }
      }
      dispatch(resetFormData());
      toastMessage("Produto atualizado", "success");
      navigate("/produtos");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  /* Building select options */

  const categoriesOptions = React.useMemo(() => {
    let options: SelectOption[] = [];
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      options.push({ label: category.name, value: category.id });
      category.children.forEach((children) => {
        options.push({ label: `${category.name} - ${children.name}`, value: children.id });
        children.children.forEach((grandChildren) => {
          options.push({
            label: `${category.name} - ${children.name} - ${grandChildren.name}`,
            value: grandChildren.id,
          });
        });
      });
    }
    return options;
  }, [categories]);

  const colorsOptions = React.useMemo(
    () => colors.map((c) => ({ label: c.name, value: c.id }) as SelectOption),
    [colors],
  );
  const sizesOptions = React.useMemo(() => sizes.map((c) => ({ label: c.name, value: c.id }) as SelectOption), [sizes]);

  React.useEffect(() => {
    if (companyData.id) fetchData();
    // eslint-disable-next-line
  }, [companyData]);

  React.useEffect(() => {
    dispatch(setVariationsData({ colors: selectedColors, sizes: selectedSizes }));
    // eslint-disable-next-line
  }, [selectedColors, selectedSizes]);

  React.useEffect(() => {
    const newPriceData = [] as { color_id: number; size_id: number; price: number }[];
    variationsData.colors.forEach((color) => {
      variationsData.sizes.forEach((size) => {
        if (!priceData.some((p) => p.color_id === color.value && p.size_id === size.value)) {
          newPriceData.push({ color_id: color.value, size_id: size.value, price: 5 });
        }
      });
    });
    if (newPriceData.length > 0) dispatch(setPriceData([...newPriceData, ...priceData]));
    // eslint-disable-next-line
  }, [variationsData, priceData]);

  React.useEffect(() => {
    if (product) {
      if (categoriesOptions.length > 0)
        setSelectedCategories(
          product.categories.map((c) => (categoriesOptions.find((o) => o.value === c.id) || {}) as SelectOption),
        );
      if (sizesOptions.length > 0) {
        setSelectedSizes(
          uniqueBy(
            "value",
            product.variations.map((v) => (sizesOptions.find((o) => o.value === v.size.id) || {}) as SelectOption),
          ),
        );
      }
      if (colorsOptions.length > 0) {
        setSelectedColors(
          uniqueBy(
            "value",
            product.variations.map((v) => (colorsOptions.find((o) => o.value === v.color.id) || {}) as SelectOption),
          ),
        );
      }
    }
    // eslint-disable-next-line
  }, [product, categoriesOptions, colors, sizes]);

  const formInitialValues = React.useMemo(() => {
    const initialValues: NewProductFormValues = {
      ref: product?.ref ?? "",
      name: product?.name ?? "",
      description: product?.description ?? "",
      price: product?.price ?? 0,
    };
    return initialValues;
  }, [product]);

  if (loadingData) return <FullScreenLoading />;

  return (
    <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className={"row"}>
          <div className={"col-12"}>
            <FormikTextInput name="ref" mask="" tooltipMessage={TooltipTexts.productCode} label={"Código do produto"} />
          </div>
          <div className={"col-12 mt-3"}>
            <FormikTextInput name="name" mask="" label={"Nome do produto"} />
          </div>
          <div className={"col-12 mt-3"}>
            <div>
              <label>Preço</label>
              <button
                className={"btn pe-0 btn-small text-decoration-underline primary"}
                type={"button"}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setLoadingPriceData(true));
                  dispatch(setPriceData(priceData.map((data) => ({ ...data, price: +values.price }))));
                  setTimeout(() => {
                    dispatch(setLoadingPriceData(false));
                  }, 400);
                }}
              >
                Aplicar a todas as variações
              </button>
              <QuestionToolTip message={TooltipTexts.applyToAll} id="apply-to-all-button" />
            </div>
            <CurrencyInput
              className="form-control"
              defaultValue={product?.price}
              style={{
                outline: "none",
                border: "1px solid #B6B6B6",
                width: "100%",
                height: "40px",
                borderRadius: "8px",
                padding: "10px 15px",
              }}
              intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              decimalSeparator={","}
              groupSeparator={"."}
              onValueChange={(e) => {
                setFieldValue("price", e?.replace(",", "."));
              }}
            />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Descrição do produto (opcional)</label>
            <textarea
              maxLength={descriptionCharsLimit}
              placeholder=" "
              className={"form-control form-control-lg"}
              style={{
                fontSize: 16,
                outline: "none",
                border: "1px solid #B6B6B6",
                width: "100%",
                height: "40px",
                borderRadius: "8px",
                padding: "10px 15px",
                minHeight: 100,
              }}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              value={values.description}
            ></textarea>
          </div>
          <div className={"col-12 mt-3"}>
            <label>Categorias</label>
            <QuestionToolTip message={TooltipTexts.categories} id="categories" />
            <Select
              options={categoriesOptions}
              isMulti
              defaultValue={selectedCategories}
              closeMenuOnSelect={false}
              onChange={(e: any) => {
                setSelectedCategories(e);
              }}
              placeholder={"Selecione as categorias"}
            />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Cores</label>
            <QuestionToolTip message={TooltipTexts.colors} id="colors" />
            <SortableSelect
              options={colorsOptions}
              isMulti
              closeMenuOnSelect={false}
              defaultValue={selectedColors}
              onChange={(e: any) => {
                setSelectedColors(e);
              }}
              onSortEnd={({ newIndex, oldIndex }) => {
                const newArray = arrayMove(selectedColors, oldIndex, newIndex);
                setSelectedColors(newArray);
              }}
              value={selectedColors}
              placeholder={"Selecione as cores"}
            />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Tamanhos</label>
            <QuestionToolTip message={TooltipTexts.sizes} id="sizes" />
            <SortableSelect
              options={sizesOptions}
              isMulti
              closeMenuOnSelect={false}
              defaultValue={selectedSizes}
              onChange={(e: any) => {
                setSelectedSizes(e);
              }}
              onSortEnd={({ newIndex, oldIndex }) => {
                const newArray = arrayMove(selectedSizes, oldIndex, newIndex);
                setSelectedSizes(newArray);
              }}
              value={selectedSizes}
              placeholder={"Selecione os tamanhos"}
            />
          </div>
          {props.refSubmitButton ? (
            <button ref={props.refSubmitButton} type={"submit"} className={"d-none"} />
          ) : (
            <div className={"col-12 border-top mt-3 pt-3"}>
              <button className={"btn btn-primary"} type="submit" disabled={isSubmitting || loading}>
                {isSubmitting || loading ? "Enviando" : "Enviar"}
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EditProductForm;
