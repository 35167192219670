import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { combineReducers } from "@reduxjs/toolkit";

import appDataReducer from "./app";
import companyDataReducer from "./company";
import ordersDataReducer from "./orders";
import editProductFormDataReducer from "./editProductFormData";
import customersDataReducer from "./customers";
import LogRocket from "logrocket";

// Creating a root reducer to persist data
const reducers = combineReducers({
  app: appDataReducer,
  company: companyDataReducer,
  orders: ordersDataReducer,
  editProductFormData: editProductFormDataReducer,
  customers: customersDataReducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(LogRocket.reduxMiddleware()),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
