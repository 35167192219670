import * as React from "react";
import FullScreenLoading from "@components/FullScreenLoading";
import Api from "@services/Api";
import { getShippingMethodLabel, getStatusLabel, toastError, toastMessage } from "@utils/functions";
import { Color, Order, OrderStatus, Product, ShippingMethod, Size } from "@utils/types";
import { useNavigate, useParams } from "react-router";
import Styled from "./styled";
import moment from "@utils/moment";
import noImage from "@assets/img/noimage.jpeg";
import { Money } from "@utils/money";
import { AiOutlinePrinter, AiOutlineWhatsApp } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import FakeLink from "@components/FakeLink";
import Modal from "react-responsive-modal";
import CurrencyInput from "react-currency-input-field";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { BsQuestionCircle } from "react-icons/bs";
import { useWindowSize } from "@utils/hooks";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@services/Redux";
import { fetchCompany } from "@services/Redux/company";
import env from "@utils/env";
interface Item {
  product: Product;
  color: Color;
  observations?: string;
  deleted_at?: Date;
  sizes: {
    size: Size;
    quantity: number;
    price: number;
    deleted_at?: Date;
  }[];
}

const OrderDetailsPage: React.FC = () => {
  const { order_code } = useParams();
  const [order, setOrder] = React.useState<Order>();
  const [loadingOrder, setLoadingOrder] = React.useState(true);
  const [isShipmentValueModalOpen, setIsShipmentValueModalOpen] = React.useState(false);
  const [isDiscountValueModalOpen, setIsDiscountValueModalOpen] = React.useState(false);
  const [isObservationModalOpen, setIsObservationModalOpen] = React.useState(false);
  const [shipmentValueInput, setShipmentValueInput] = React.useState(order?.shipment_value ?? "");
  const [discountValueInput, setDiscountValueInput] = React.useState(order?.discounts_value ?? "");
  const [observationInputValue, setObservationInputValue] = React.useState(order?.observations ?? "");
  const [timelineText, setTimelineText] = React.useState("");
  const [maxInstallmentsCountValue, setMaxInstallmentsCountValue] = React.useState("12");
  const [isConfirmOrderModalOpen, setIsConfirmOrderModalOpen] = React.useState(false);
  const isLogged = localStorage.getItem("user_token") !== "" && localStorage.getItem("user_token") !== null;
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: companyData } = useSelector((state: RootState) => state.company);

  const fetchOrder = async () => {
    try {
      setLoadingOrder(true);
      const { data } = await Api.get(`/order/code/${order_code}`);
      setOrder(data.order);
      if (data.order?.shipment_value) setShipmentValueInput(data.order.shipment_value);
      if (data.order?.discounts_value) setDiscountValueInput(data.order.discounts_value);
      if (data.order?.observations) setObservationInputValue(data.order.observations);
      setLoadingOrder(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fetchOrder();
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (order?.company_id) {
      dispatch(fetchCompany(`${order.company_id}`));
    }
  }, [order]);

  React.useEffect(() => {
    if (order?.id) {
      setTimelineText(`
      Criado às ${moment(order?.created_at).format("DD/MM/YYYY HH:mm")}<br/>
      ${
        order?.finished_at
          ? `Enviado pelo cliente em ${moment(order?.finished_at).format("DD/MM/YYYY HH:mm")}<br/>`
          : ""
      }
      ${
        order?.confirmed_at
          ? `Confirmado pelo vendedor em ${moment(order?.confirmed_at).format("DD/MM/YYYY HH:mm")}<br/>`
          : ""
      }
      ${
        order?.last_payment_attempt
          ? `Última tentativa de pagamento em ${moment(order?.last_payment_attempt).format("DD/MM/YYYY HH:mm")}<br/>`
          : ""
      }
      ${
        order?.entered_in_analysis_at
          ? `Entrou em análise em ${moment(order?.entered_in_analysis_at).format("DD/MM/YYYY HH:mm")}<br/>`
          : ""
      }
      ${order?.paid_at ? `Pago em ${moment(order?.paid_at).format("DD/MM/YYYY HH:mm")}` : ""}
     `);
    }
    //eslint-disable-next-line
  }, [order, timelineText]);

  const handlePrint = () => {
    window.print();
  };

  const confirmOrder = async () => {
    try {
      setLoadingOrder(true);
      await Api.post(`/order/${order?.id}/confirm`, { max_installments_count: maxInstallmentsCountValue });
      toastMessage("O pedido agora está aguardando pagamento", "success");
      fetchOrder();
      setIsConfirmOrderModalOpen(false);
    } catch (error) {
      setLoadingOrder(false);
      toastError(error);
    }
  };

  const backToCreated = async () => {
    try {
      setLoadingOrder(true);
      await Api.post(`/order/${order?.id}/return_to_created`);
      toastMessage("O cliente pode editar o pedido novamente.", "success");
      fetchOrder();
    } catch (error) {
      setLoadingOrder(false);
      toastError(error);
    }
  };

  const handleShipmentValueUpdateRequest = async () => {
    try {
      await Api.post(`/order/${order?.id}/update_shipment_value`, { shipment_value: shipmentValueInput });
      toastMessage("Valor do frete atualizado com sucesso.", "success");
      setIsShipmentValueModalOpen(false);
      fetchOrder();
    } catch (error) {
      toastError(error);
    }
  };

  const handleDiscountValueUpdateRequest = async () => {
    try {
      await Api.post(`/order/${order?.id}/update_discount_value`, { discount_value: discountValueInput });
      toastMessage("Valor do desconto atualizado com sucesso.", "success");
      setIsDiscountValueModalOpen(false);
      fetchOrder();
    } catch (error) {
      toastError(error);
    }
  };

  const handleCancelOrderRequest = async () => {
    try {
      await Api.post(`/order/${order?.id}/cancel`);
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate("/pedidos", { replace: true });
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleObservationUpdateRequest = async () => {
    try {
      await Api.post(`/order/${order?.id}/observations`, { observations: observationInputValue });
      toastMessage("Observações atualizadas com sucesso.", "success");
      setIsObservationModalOpen(false);
      fetchOrder();
    } catch (error) {
      toastError(error);
    }
  };

  const handleBackToCreatedStatusRequest = () => {
    confirmAlert({
      title: "Atenção!",
      message:
        "Se você não estiver usando integrações de pagamento, é possível que o cliente já tenha efetuado uma tentativa de pagamento. Deseja continuar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            backToCreated();
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleCancelOrderBtnClick = () => {
    confirmAlert({
      title: "Atenção!",
      message: "Deseja cancelar este pedido?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            handleCancelOrderRequest();
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleCopyPaymentUrl = () => {
    navigator.clipboard.writeText(`https://link.emepag.com.br/${order?.emepag_order_code}`);
    toastMessage(`Link copiado.`, "success");
  };

  const handleCopyEmepagV2PaymentUrl = () => {
    navigator.clipboard.writeText(`https://v2.emepag.com.br/pagar/${order?.emepag_v2_order_code}`);
    toastMessage(`Link copiado.`, "success");
  };

  const handleSendPaymentLinkToClient = () => {
    const message = `Olá, aqui está o link para pagamento do seu pedido:
https://link.emepag.com.br/${order?.emepag_order_code}`;
    window
      .open(
        `https://api.whatsapp.com/send?phone=55${order?.customer_phone?.replace(/\D+/g, "")}&text=${encodeURI(
          message,
        )}`,
        "_blank",
      )
      ?.focus();
  };

  const handleSendEmepagV2PaymentLinkToClient = () => {
    const message = `Olá, aqui está o link para pagamento do seu pedido:
https://v2.emepag.com.br/pagar/${order?.emepag_order_code}`;
    window
      .open(
        `https://api.whatsapp.com/send?phone=55${order?.customer_phone?.replace(/\D+/g, "")}&text=${encodeURI(
          message,
        )}`,
        "_blank",
      )
      ?.focus();
  };

  const handleOpenCustomerWhatsApp = () => {
    window
      .open(`https://api.whatsapp.com/send?phone=55${order?.customer_phone?.replace(/\D+/g, "")}`, "_blank")
      ?.focus();
  };

  if (loadingOrder || !order) return <FullScreenLoading />;

  const items = order.items.reduce((cur, item) => {
    const productIndex = cur.findIndex(
      (c) => c.product.id === item.product.id && c.color.id === item.variation.color.id,
    );

    if (productIndex === -1) {
      cur.push({
        product: item.product,
        observations: item.observations,
        color: item.variation.color,
        sizes: [
          {
            size: { ...item.variation.size },
            quantity: item.quantity,
            price: item.variation.price,
            deleted_at: item.deleted_at,
          },
        ],
        deleted_at: item.deleted_at,
      });
    } else {
      cur[productIndex].sizes?.push({ ...item.variation, quantity: item.quantity });
    }
    return cur;
  }, [] as Item[]);

  return (
    <Styled.RootContainer>
      <Styled.ContentContainer className={"container"}>
        <Styled.HeaderContainer className={"row"}>
          <div className={"col-md-auto mb-4 d-flex justify-content-between w-100"}>
            <img
              src={
                companyData.logo_image
                  ? `${companyData.logo_image.base_url}/companies/${companyData.id}/media/thumb_3/${companyData.logo_image.key}`
                  : env.REACT_APP_LOGO_URL
              }
              alt="logo"
              style={{ maxWidth: 200, maxHeight: 60 }}
            />
            <div>
              <button className={"no-print no-border"} style={{ width: "auto" }} onClick={handlePrint}>
                <AiOutlinePrinter size={32} />
              </button>
              {order.customer_phone ? (
                <button
                  className={"no-print no-border ms-2"}
                  style={{ width: "auto" }}
                  onClick={handleOpenCustomerWhatsApp}
                >
                  <AiOutlineWhatsApp size={32} />
                </button>
              ) : null}
            </div>
          </div>
          <div>
            <div className={"row no-print"}>
              {order.emepag_order_code ? (
                <div className={"col-6 col-md-auto mt-2"}>
                  <Menu
                    menuButton={
                      <button className={" me-3"}>
                        <span>Enviar link de pagamento</span>
                      </button>
                    }
                  >
                    <MenuItem onClick={handleSendPaymentLinkToClient}>Enviar para o whatsapp do cliente</MenuItem>
                    <MenuItem onClick={handleCopyPaymentUrl}>Apenas copiar link</MenuItem>
                  </Menu>
                </div>
              ) : null}
              {order.emepag_v2_order_code ? (
                <div className={"col-6 col-md-auto mt-2"}>
                  <Menu
                    menuButton={
                      <button className={" me-3"}>
                        <span>Enviar link de pagamento</span>
                      </button>
                    }
                  >
                    <MenuItem onClick={handleSendEmepagV2PaymentLinkToClient}>
                      Enviar para o whatsapp do cliente
                    </MenuItem>
                    <MenuItem onClick={handleCopyEmepagV2PaymentUrl}>Apenas copiar link</MenuItem>
                  </Menu>
                </div>
              ) : null}

              {isLogged && (
                <div className={"col-6 col-md-auto mt-2"}>
                  {order.status === OrderStatus.CREATED || order.status === OrderStatus.WAITING_SELLER_CONFIRMATION ? (
                    <button
                      onClick={() => {
                        setIsConfirmOrderModalOpen(true);
                      }}
                    >
                      <span>Liberar para pagamento</span>
                    </button>
                  ) : (
                    <button
                      className={" me-3"}
                      onClick={() => {
                        navigate(`/pedidos/${order_code}/pagamentos`);
                      }}
                    >
                      <span>Pagamentos</span>
                    </button>
                  )}
                </div>
              )}

              {isLogged && order.status === OrderStatus.WAITING_SELLER_CONFIRMATION && (
                <div className={"col-6 col-md-auto mt-2"}>
                  <button className={"me-3"} onClick={handleBackToCreatedStatusRequest}>
                    <span>Permitir edição do pedido</span>
                  </button>
                </div>
              )}
              {isLogged && (
                <div className={"col-6 col-md-auto mt-2"}>
                  <button
                    onClick={() => {
                      setIsObservationModalOpen(true);
                    }}
                  >
                    <span>Alterar observações</span>
                  </button>
                </div>
              )}
              {isLogged &&
                [OrderStatus.READY_TO_PAY, OrderStatus.WAITING_SELLER_CONFIRMATION].includes(order.status) && (
                  <div className={"col-6 col-md-auto mt-2"}>
                    <button className={"me-3 cancel-btn"} onClick={handleCancelOrderBtnClick}>
                      <span>Cancelar pedido</span>
                    </button>
                  </div>
                )}
            </div>
          </div>
        </Styled.HeaderContainer>
        <Styled.LightContainer>
          <Styled.OrderInfoContainer className={"row"}>
            <div className={"col-4 col-md-3 mt-1"}>
              <div>Pedido</div>
              <div>
                <b>{order_code}</b>
              </div>
            </div>
            <div className={"col-4 col-md-3 mt-1"}>
              <div>Cliente</div>
              <div>
                <b>{order?.customer_name}</b>
              </div>
            </div>
            {order.customer_document ? (
              <div className={"col-4 col-md-3 mt-1"}>
                <div>Documento</div>
                <div>
                  <b>{order?.customer_document}</b>
                </div>
              </div>
            ) : null}
            {order.customer_birthdate ? (
              <div className={"col-4 col-md-3 mt-1"}>
                <div>Data de nasc.</div>
                <div>
                  <b>{order?.customer_birthdate}</b>
                </div>
              </div>
            ) : null}
            <div className={"col-4 col-md-3 mt-1"}>
              <div>WhatsApp</div>
              <div>
                <b>{order?.customer_phone}</b>
              </div>
            </div>
            <div className={"col-4 col-md-3 mt-1"}>
              <div>Situação</div>
              <div>
                <b>{getStatusLabel(order.status)}</b>
              </div>
            </div>
            <div className={"col col-md mt-1"}>
              <div>
                <span className={"me-1"}>Data</span>
                <BsQuestionCircle data-tip={timelineText} />
              </div>
              <div>
                <b>
                  {order.status === OrderStatus.CREATED
                    ? `${moment(order?.created_at).format("DD/MM/YYYY HH:mm")} (Criado)`
                    : order.status === OrderStatus.WAITING_SELLER_CONFIRMATION
                    ? `${moment(order?.finished_at).format("DD/MM/YYYY HH:mm")} (Finalizado pelo cliente)`
                    : order.status === OrderStatus.READY_TO_PAY
                    ? `${moment(order?.confirmed_at).format("DD/MM/YYYY HH:mm")} (Confirmado pelo vendedor)`
                    : order.status === OrderStatus.IN_ANALYSIS
                    ? `${moment(order?.entered_in_analysis_at).format("DD/MM/YYYY HH:mm")} (Entrou em análise)`
                    : order.status === OrderStatus.WAITING_PAYMENT_CONFIRMATION
                    ? `${moment(order?.last_payment_attempt).format(
                        "DD/MM/YYYY HH:mm",
                      )} (Última tentativa de pagamento)`
                    : order.status === OrderStatus.PAID
                    ? `${moment(order?.paid_at).format("DD/MM/YYYY HH:mm")} (Pago)`
                    : `${moment(order?.created_at).format("DD/MM/YYYY HH:mm")} (Criado)`}
                </b>
              </div>
            </div>
            {order.seller && (
              <div className="col col-md mt-1">
                <div>Vendedor</div>
                <div>
                  <b>{order.seller.name}</b>
                </div>
              </div>
            )}
            {/* Shipping data */}
            {order.shipment ? (
              <>
                <div className={"col-12 border-bottom my-2"}></div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Envio</div>
                  <div>
                    <b>{getShippingMethodLabel(order.shipment.shipping_method)}</b>
                  </div>
                </div>
                {order.shipment.shipping_method === ShippingMethod.EXCURSION && (
                  <>
                    <div className={"col-4 col-md-3 mt-1"}>
                      <div>Nome da exc.</div>
                      <div>
                        <b>{order.shipment.excursion_name}</b>
                      </div>
                    </div>
                    <div className={"col-4 col-md-3 mt-1"}>
                      <div>Telefone da exc.</div>
                      <div>
                        <b>{order.shipment.excursion_phone}</b>
                      </div>
                    </div>
                    <div className={"col-4 col-md-3 mt-1"}>
                      <div>Endereço da exc.</div>
                      <div>
                        <b>{order.shipment.excursion_address}</b>
                      </div>
                    </div>
                    <div className={"col-4 col-md-3 mt-1"}>
                      <div>Ponto de referência da exc.</div>
                      <div>
                        <b>{order.shipment.excursion_address_reference}</b>
                      </div>
                    </div>
                  </>
                )}
                <div className={"col-12 border-bottom my-2"} />
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>CEP</div>
                  <div>
                    <b>{order.shipment.zip_code}</b>
                  </div>
                </div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Rua</div>
                  <div>
                    <b>{order.shipment.street}</b>
                  </div>
                </div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Número</div>
                  <div>
                    <b>{order.shipment.number}</b>
                  </div>
                </div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Bairro</div>
                  <div>
                    <b>{order.shipment.neighborhood}</b>
                  </div>
                </div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Complemento</div>
                  <div>
                    <b>{order.shipment.complement}</b>
                  </div>
                </div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Cidade</div>
                  <div>
                    <b>{order.shipment.city}</b>
                  </div>
                </div>
                <div className={"col-4 col-md-3 mt-1"}>
                  <div>Estado</div>
                  <div>
                    <b>{order.shipment.state}</b>
                  </div>
                </div>
              </>
            ) : null}
            {/* End of shipping data */}
          </Styled.OrderInfoContainer>
        </Styled.LightContainer>

        {/* Order Items */}
        <Styled.LightContainer className={"mt-4"} style={{ overflow: "auto" }}>
          <Styled.ItemsTable>
            <thead>
              <tr>
                <th>Produto</th>
                <th className="d-flex justify-content-center">Cor</th>
                <th>Quantidade</th>
                <th>Tamanho</th>
                <th className="d-flex justify-content-center">Preço</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                const productImage = item.product.images?.filter((i) => i.deleted_at === null)?.[0];
                const colorImage = item.color.image;
                const totalValue = item.sizes.reduce(
                  (cur, size) => Money(cur).add(Money(size.price).multiply(size.quantity)).value,
                  0,
                );

                return (
                  <tr
                    className={`${index !== items.length - 1 ? "border-bottom" : ""} ${
                      item.deleted_at ? "no-print" : ""
                    }`}
                    key={index}
                  >
                    <td>
                      <div className={"d-flex flex-row align-items-center"}>
                        <div>
                          <img
                            alt="product"
                            src={
                              productImage
                                ? `${productImage.base_url}/products/${item.product.id}/thumb_1/${productImage.key}`
                                : noImage
                            }
                          />
                        </div>
                        <div className={`ms-2`} style={{ maxWidth: 350, minWidth: 150, whiteSpace: "normal" }}>
                          <b>{item.product.ref}</b> - {item.product.name}{" "}
                          {item.observations ? `. Obs: ${item.observations}` : null}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"d-flex flex-row align-items-center"}>
                        <img
                          alt="color"
                          src={
                            colorImage
                              ? `${colorImage.base_url}/colors/${item.color.id}/thumb_1/${colorImage.key}`
                              : noImage
                          }
                        />
                        <div className={"ms-2"}>{item.color.name}</div>
                      </div>
                    </td>

                    <td>
                      {item.sizes.map((size, index) => (
                        <div className="d-flex flex-column p-0 m-0" key={index}>
                          <div
                            className={`me-2 text-left text-md-center ${size.deleted_at ? "text-danger" : ""}`}
                            key={index}
                          >
                            {size?.quantity} peça{size?.quantity > 1 ? "s " : " "}
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {item.sizes.map((size, index) => (
                        <div className="d-flex flex-column p-0 m-0" key={index}>
                          <div
                            className={`me-2 text-left text-md-center ${size.deleted_at ? "text-danger" : ""}`}
                            key={index}
                          >
                            <b>{size?.size?.name}</b>
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {item.sizes.map((size, index) => (
                        <div className="d-flex flex-column p-0 m-0" key={index}>
                          <div
                            className={`me-2 text-left text-md-center ${size.deleted_at ? "text-danger" : ""}`}
                            key={index}
                          >
                            {Money(size.quantity).multiply(size.price).format()}
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>{Money(totalValue).format()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Styled.ItemsTable>
        </Styled.LightContainer>
        <Styled.LightContainer className={"mt-4 row p-0 m-0"}>
          <div
            className={`col-6 d-flex flex-row justify-content-between align-items-center ${
              width >= 768 ? "border-end" : "border-bottom"
            }`}
            style={{ minHeight: 100 }}
          >
            <div>
              <div>Total de peças</div>
              <div>
                <b>
                  {order.items.reduce((acc, cur) => acc + cur.quantity, 0)} -{" "}
                  {Money(
                    order.items.reduce(
                      (acc, cur) =>
                        cur.deleted_at ? acc : Money(acc).add(Money(cur.price).multiply(cur.quantity)).value,
                      0,
                    ),
                  ).format()}
                </b>
              </div>
            </div>
            <div className={"text-end"}>
              <div>Frete</div>
              {order.shipment_value ? (
                <div>
                  <b>{Money(order.shipment_value).format()}</b>
                  {isLogged && (
                    <FakeLink
                      className={"text-primary ms-1 no-print"}
                      onClick={() => {
                        setIsShipmentValueModalOpen(true);
                      }}
                    >
                      (alterar)
                    </FakeLink>
                  )}
                </div>
              ) : isLogged ? (
                <div>
                  <FakeLink
                    className={"text-primary"}
                    onClick={() => {
                      setIsShipmentValueModalOpen(true);
                    }}
                  >
                    Informar frete
                  </FakeLink>
                </div>
              ) : (
                Money(0).format()
              )}
            </div>
          </div>
          <div
            className={"col-6 d-flex justify-content-between align-items-center flex-row"}
            style={{ minHeight: 100 }}
          >
            {order.coupon ? (
              <div className="flex flex-column">
                <div>Cupom</div>
                <div>
                  <b>{order.coupon.code}</b>
                </div>
              </div>
            ) : null}
            {order.discounts_value ? (
              <div className="flex flex-column">
                <div>Descontos</div>
                <div>
                  <b>{Money(order.discounts_value ?? 0).format()}</b>
                  <FakeLink
                    className={"text-primary ms-1 no-print"}
                    onClick={() => {
                      setIsDiscountValueModalOpen(true);
                    }}
                  >
                    (alterar)
                  </FakeLink>
                </div>
              </div>
            ) : (
              <div className="flex flex-column">
                <div>Descontos</div>
                <FakeLink
                  className={"text-primary no-print"}
                  onClick={() => {
                    setIsDiscountValueModalOpen(true);
                  }}
                >
                  Informar Desconto
                </FakeLink>
              </div>
            )}
            <div className="flex flex-column">
              <div>Total a pagar</div>
              <b>
                {Money(order.value)
                  .subtract(order.discounts_value ?? 0)
                  .add(order.shipment_value ?? 0)
                  .format()}
              </b>
            </div>
          </div>
        </Styled.LightContainer>
        {order.observations ? (
          <Styled.LightContainer className={"mt-3"}>
            <div className={"border-bottom mb-2"}>
              <b>Observações</b>
            </div>
            <span>{order.observations}</span>
          </Styled.LightContainer>
        ) : null}
      </Styled.ContentContainer>
      <Modal
        center
        open={isShipmentValueModalOpen}
        onClose={() => {
          setIsShipmentValueModalOpen(false);
        }}
      >
        <div>
          <h4>Atualizar frete</h4>
          <label>Valor do frete</label>
          <CurrencyInput
            className="form-control"
            style={{
              outline: "none",
              border: "1px solid #B6B6B6",
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              padding: "10px 15px",
              minWidth: 250,
            }}
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            decimalSeparator={","}
            groupSeparator={"."}
            value={shipmentValueInput}
            onValueChange={(e) => {
              setShipmentValueInput(e?.replace(",", ".") ?? "");
            }}
          />
          <button
            className={"btn btn-primary mt-3 w-100"}
            onClick={(e) => {
              e.preventDefault();
              handleShipmentValueUpdateRequest();
            }}
          >
            Enviar
          </button>
        </div>
      </Modal>
      <Modal
        center
        open={isDiscountValueModalOpen}
        onClose={() => {
          setIsDiscountValueModalOpen(false);
        }}
      >
        <div>
          <h4>Atualizar desconto</h4>
          <label>Valor do desconto</label>
          <CurrencyInput
            className="form-control"
            style={{
              outline: "none",
              border: "1px solid #B6B6B6",
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              padding: "10px 15px",
              minWidth: 250,
            }}
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            decimalSeparator={","}
            groupSeparator={"."}
            value={discountValueInput}
            onValueChange={(e) => {
              setDiscountValueInput(e?.replace(",", ".") ?? "");
            }}
          />
          <button
            className={"btn btn-primary mt-3 w-100"}
            onClick={(e) => {
              e.preventDefault();
              handleDiscountValueUpdateRequest();
            }}
          >
            Enviar
          </button>
        </div>
      </Modal>
      <Modal
        center
        open={isObservationModalOpen}
        onClose={() => {
          setIsObservationModalOpen(false);
        }}
      >
        <div>
          <h4>Atualizar observações</h4>
          <label>Observações</label>
          <textarea
            value={observationInputValue}
            onChange={(e) => setObservationInputValue(e.target.value)}
            className="form-control"
            style={{ minWidth: 400, minHeight: 150 }}
          />
          <button
            className={"btn btn-primary mt-3 w-100"}
            onClick={(e) => {
              e.preventDefault();
              handleObservationUpdateRequest();
            }}
          >
            Enviar
          </button>
        </div>
      </Modal>
      <Modal open={isConfirmOrderModalOpen} onClose={() => setIsConfirmOrderModalOpen(false)} center>
        <Styled.ConfirmOrderModal>
          <h2>Atenção.</h2>
          <p>Deseja liberar o pedido para pagamento?</p>
          <div>
            <label>Número máximo de parcelas</label>
            <input
              className={"form-control"}
              type={"number"}
              value={maxInstallmentsCountValue}
              placeholder={"Deixe em branco para utilizar o padrão da marca"}
              onChange={(e) => {
                setMaxInstallmentsCountValue(e.target.value);
              }}
            />
          </div>
          <div className={"mt-4"}>
            <button
              className={"btn btn-outline-danger"}
              onClick={() => {
                setIsConfirmOrderModalOpen(false);
              }}
            >
              Cancelar
            </button>
            <button onClick={confirmOrder} className={"btn btn-primary ms-3"}>
              Sim
            </button>
          </div>
        </Styled.ConfirmOrderModal>
      </Modal>
    </Styled.RootContainer>
  );
};

export default OrderDetailsPage;
