import { RootState } from "@services/Redux";
import { useSelector } from "react-redux";

export const BlingIntegrationCard: React.FC = () => {
  const { bling_integration } = useSelector((state: RootState) => state.company.data);

  const isApiActivated = bling_integration?.is_authorized;

  return (
    <div>
      <div
        className={"p-0 m-0 rounded-3 d-flex flex-column align-items-center"}
        style={{ width: 200, backgroundColor: "white" }}
      >
        <div className="mt-3 ">
          <h2 className="font-weight-bold">Bling</h2>
        </div>
        <div className="d-flex flex-row mb-3 mt-1">
          <div>Situação: </div>
          {isApiActivated ? (
            <div className="text-success font-weight-bold ms-1">Ativada</div>
          ) : (
            <div className="text-danger ms-1">Desativada</div>
          )}
        </div>
        {!isApiActivated && (
          <a
            href={`https://www.bling.com.br/OAuth2/views/authorization.php?response_type=code&client_id=4d70ff02e3e2583f5ac6d884afdc69c3bbd30d49&state=2f6cc7a3641ed44e739febd5265f24e9&scopes=98308+98309+98310+98314+5990556+6631498+199272829+318257556+318257565+318257570+333936575+363921589+363921592+363953167+791588404+1869535257+5862218180+13645012997`}
            target="_blank"
            rel="noreferrer"
            className="mb-3 btn btn-primary"
          >
            Ativar
          </a>
        )}
      </div>
    </div>
  );
};
